import { AnyAction } from "redux";
import {
  defaultFilters,
  defaultFilterOptions,
  defaultFiltersLoaded,
} from "../../constants/filters";
import { IBookmark } from "../../types/bookmark.types";
import { IUnitsData, IUnitsTotalData } from "../../types/data.types";
import {
  FilterType,
  IFilter,
  IFilterLoaded,
  IFilterOptions,
  UpcDetailData,
} from "../../types/filters.types";
import { IFilterOption } from "../../types/dropdown.types";
import { BarcodeType } from "../../types/barcode.types";

export const transformFilterOptions = (
  filterOptions: IFilterOptions
): IFilter => ({
  warehouseDivision: filterOptions.warehouseDivision
    .map((option) => option.value)
    .join(","),
  warehouse: filterOptions.warehouse.map((option) => option.value).join(","),
  distribution: filterOptions.distribution
    .map((option) => option.value)
    .join(","),
  dsdVendor: filterOptions.dsdVendor.map((option) => option.value).join(","),
  retailDivision: filterOptions.retailDivision
    .map((option) => option.value)
    .join(","),
  retailStore: filterOptions.retailStore
    .map((option) => option.value)
    .join(","),
  smic: filterOptions.smic.map((option) => option.value).join(","),
  upc: filterOptions.upc.map((option) => option.value).join(","),
  cic: filterOptions.cic.length > 0 ? `${filterOptions.cic[0].value}` : "",
  department: filterOptions.department
    .map((option) => option.value.split(" - ")[0])
    .join(","),
  retailSection: filterOptions.retailSection
    .map((option) => option.value.split(" - ")[0])
    .join(","),
});

export const transformFilters = (
  type: FilterType,
  filters: IFilterOption[]
) => {
  if (type === "cic" || type === "upc") {
    return filters.map((option: IFilterOption) => ({
      label: option.label,
      value: option.value,
      description: option.description
        ? `${option.label} - ${option.description.split("- ")[1]}`
        : option.label,
    }));
  }
  return filters;
};

export const INIT_DASHBOARD_STATE = "INIT_DASHBOARD_STATE";
export const ON_CHANGE_FILTERS = "ON_CHANGE_FILTERS";
export const UPDATE_PRODUCT_DESCRIPTION = "UPDATE_PRODUCT_DESCRIPTION";
export const GET_CIC_AND_UPC_DETAILS = "GET_CIC_AND_UPC_DETAILS";
export const GET_CIC_AND_UPC_DETAILS_SUCCESS =
  "GET_CIC_AND_UPC_DETAILS_SUCCESS";
export const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";
export const GET_FILTER_OPTIONS_SUCCESS = "GET_FILTER_OPTIONS_SUCCESS";
export const GET_FILTER_OPTIONS_ERROR = "GET_FILTER_OPTIONS_ERROR";
export const GET_UNITS_DATA = "GET_UNITS_DATA";
export const GET_UNITS_DATA_SUCCESS = "GET_UNITS_DATA_SUCCESS";
export const GET_UNITS_DATA_ERROR = "GET_UNITS_DATA_ERROR";
export const FLOATING_RESET_ACTION = "FLOATING_RESET_ACTION";
export const FILTERS_RESET_ACTION = "FILTERS_RESET_ACTION";
export const REFRESH_DATA_ACTION = "REFRESH_DATA_ACTION";
export const ADD_BOOKMARK_ACTION = "ADD_BOOKMARK_ACTION";
export const ON_SELECT_BOOKMARK = "ON_SELECT_BOOKMARK";
export const CHANGE_EXPAND_FLOATING_MENU_ACTION =
  "CHANGE_EXPAND_FLOATING_MENU_ACTION";
export const FILTER_ACCORDIAN_OPEN = "FILTER_ACCORDIAN_OPEN";
export const ON_DELETE_BOOKMARK = "ON_DELETE_BOOKMARK";
export const ON_RENAME_BOOKMARK = "ON_RENAME_BOOKMARK";
export const MARK_AS_DEFAULT = "MARK_AS_DEFAULT";

export interface IDashboardReducer {
  isStateInitialized: boolean;
  bookmarks: IBookmark[];
  filterOptionsLoading: IFilterLoaded;
  selectedFilters: IFilter;
  selectedFilterOptions: IFilterOptions;
  filterOptions: IFilterOptions;
  productDescription: string;
  isUnitsDataLoading: number;
  sellingUnitsData: IUnitsData;
  shippingUnitsData: IUnitsData;
  vendorUnitsData: IUnitsData;
  totalWarehouseData: IUnitsTotalData;
  totalStoreData: IUnitsTotalData;
  floatingResetKey: number;
  filtersResetKey: number;
  refreshDataKey: number;
  selectedBookmarkIdx: number;
  selectedBookmarkIdxDefault: number;
  isBookmarkFilled: boolean;
  floatingMenuChange: boolean;
  filterAccordianOpen: boolean;
}

export const filterAccordianOpenAction = (value: boolean) => ({
  type: FILTER_ACCORDIAN_OPEN,
  value,
});

export const onRenameBookmark = (name: string, id: number) => ({
  type: ON_RENAME_BOOKMARK,
  value: {
    name,
    id,
  },
});

export const refreshLatestDataAction = () => ({
  type: REFRESH_DATA_ACTION,
});

export const markAsDefault = (value: number) => ({
  type: MARK_AS_DEFAULT,
  value,
});

export const initDashboardState = () => ({
  type: INIT_DASHBOARD_STATE,
});

export const changeDashboardFilters = (value: IFilterOptions) => ({
  type: ON_CHANGE_FILTERS,
  value,
});

export const fetchProductDescription = (cic: IFilterOption[]) => ({
  type: UPDATE_PRODUCT_DESCRIPTION,
  value: {
    cic,
  },
});

export const fetchCICAndUPCDetails = (data: string, type: BarcodeType) => ({
  type: GET_CIC_AND_UPC_DETAILS,
  value: {
    data,
    type,
  },
});

export const fetchCICAndUPCDetailsSuccess = (
  data: UpcDetailData,
  type: BarcodeType
) => ({
  type: GET_CIC_AND_UPC_DETAILS_SUCCESS,
  value: { data, type },
});

export const addBookmark = () => ({
  type: ADD_BOOKMARK_ACTION,
});

export const onSelectBookmark = (value: number) => ({
  type: ON_SELECT_BOOKMARK,
  value,
});

export const onDeleteBookmark = (value: number) => ({
  type: ON_DELETE_BOOKMARK,
  value,
});

export const fetchFilterOptions = (
  type: string,
  query: string,
  filters: IFilter
) => ({
  type: GET_FILTER_OPTIONS,
  value: {
    type,
    query,
    filters,
  },
});

export const fetchFilterOptionsSuccess = (
  type: string,
  options: IFilterOption[]
) => ({
  type: GET_FILTER_OPTIONS_SUCCESS,
  value: { type, options },
});

export const fetchFilterOptionsError = (type: string) => ({
  type: GET_FILTER_OPTIONS_ERROR,
  value: { type },
});

export const fetchUnitsData = (filters: IFilterOptions) => ({
  type: GET_UNITS_DATA,
  value: {
    filters,
  },
});

interface AllUnitsData {
  sellingUnits: IUnitsData;
  shippingUnits: IUnitsData;
  vendorUnits: IUnitsData;
  totalWarehouseData: IUnitsTotalData;
  totalStoreData: IUnitsTotalData;
}

export const fetchUnitsDataSuccess = (value: AllUnitsData) => ({
  type: GET_UNITS_DATA_SUCCESS,
  value,
});

export const fetchUnitsDataError = () => ({
  type: GET_UNITS_DATA_ERROR,
});

export const floatingResetAllAction = () => ({
  type: FLOATING_RESET_ACTION,
});

export const filtersResetAllAction = () => ({
  type: FILTERS_RESET_ACTION,
});

export const changeExpandFloatingMenuAction = (value: boolean) => ({
  type: CHANGE_EXPAND_FLOATING_MENU_ACTION,
  value,
});

export const initialState: IDashboardReducer = {
  isStateInitialized: false,
  bookmarks: localStorage.getItem("user_bookmarks")
    ? JSON.parse(localStorage.getItem("user_bookmarks") ?? "[]")
    : [],
  filterOptionsLoading: defaultFiltersLoaded,
  selectedFilters: defaultFilters,
  selectedFilterOptions: defaultFilterOptions,
  filterOptions: defaultFilterOptions,
  productDescription: "",
  isUnitsDataLoading: 0,
  sellingUnitsData: {},
  shippingUnitsData: {},
  vendorUnitsData: {},
  totalWarehouseData: {},
  totalStoreData: {},
  floatingResetKey: 0,
  filtersResetKey: 0,
  refreshDataKey: 0,
  selectedBookmarkIdx: -1,
  selectedBookmarkIdxDefault: -1,
  isBookmarkFilled: false,
  floatingMenuChange: false,
  filterAccordianOpen: false,
};

export const dashboardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case INIT_DASHBOARD_STATE:
      let bookmarkIdNew = -1;
      let bookmarkId1 = -1;
      const filters1 =
        state.bookmarks.length > 0
          ? (JSON.parse(state.bookmarks[0].filters) as IFilterOptions)
          : defaultFilterOptions;
      // initial load default page
      const defaultBookmarkId = localStorage.getItem("mark_as_default");
      if (defaultBookmarkId !== null) {
        bookmarkId1 = Number(defaultBookmarkId);
      }
      const selectedBookmarkId = localStorage.getItem("selected_bookmark");
      if (selectedBookmarkId !== null) {
        bookmarkIdNew = Number(selectedBookmarkId);
      }
      return {
        ...state,
        selectedFilters: transformFilterOptions(filters1),
        selectedFilterOptions: filters1,
        selectedBookmarkIdxDefault: bookmarkId1,
        selectedBookmarkIdx: bookmarkIdNew,
        isBookmarkFilled: bookmarkIdNew >= 0,
        isStateInitialized: true,
      };
    case ON_CHANGE_FILTERS:
      const filters2 = action.value;
      return {
        ...state,
        selectedFilters: transformFilterOptions(filters2),
        selectedFilterOptions: filters2,
        selectedBookmarkIdx: -1,
        isBookmarkFilled: false,
      };
    case UPDATE_PRODUCT_DESCRIPTION:
      return {
        ...state,
        productDescription:
          action.value.cic.length > 0 ? action.value.cic[0].description : "",
      };
    /* istanbul ignore next */
    case GET_CIC_AND_UPC_DETAILS:
      const cicOptions1 =
        action.value.type === "cic"
          ? [{ label: action.value.data, value: action.value.data }]
          : [];
      const filters3 = {
        ...state.selectedFilterOptions,
        cic: cicOptions1,
      };
      return {
        ...state,
        productDescription: "",
        selectedFilters: transformFilterOptions(filters3),
        selectedFilterOptions: filters3,
        selectedBookmarkIdx: -1,
        isBookmarkFilled: false,
        filterOptionsLoading: {
          ...state.filterOptionsLoading,
          upc: true,
          cic: action.value.type === "upc",
        },
        filterOptions: {
          ...state.filterOptions,
          upc: [],
          cic: cicOptions1,
        },
      };
    /* istanbul ignore next */
    case GET_CIC_AND_UPC_DETAILS_SUCCESS:
      const upcOptions = action.value.data.upcs.map((upc: string) => ({
        label: upc,
        value: upc,
      }));
      const cicOptions2 = [
        { label: action.value.data.cic, value: action.value.data.cic },
      ];
      const filters4 = {
        ...state.selectedFilterOptions,
        upc: upcOptions,
        cic: cicOptions2,
      };
      return {
        ...state,
        productDescription: action.value.data.description,
        selectedFilters: transformFilterOptions(filters4),
        selectedFilterOptions: filters4,
        filterOptionsLoading: {
          ...state.filterOptionsLoading,
          upc: false,
          ...(action.value.type === "upc" ? { cic: false } : {}),
        },
        filterOptions: {
          ...state.filterOptions,
          upc: upcOptions,
          ...(action.value.type === "upc" ? { cic: cicOptions2 } : {}),
        },
      };
    /* istanbul ignore next */
    case ON_RENAME_BOOKMARK:
      const newName = action.value.name;
      const bookmarkId2 = action.value.id;
      let storedBookmarksJSONDetails = localStorage.getItem("user_bookmarks");
      const storedBookmarksDetails = storedBookmarksJSONDetails
        ? JSON.parse(storedBookmarksJSONDetails)
        : [];
      storedBookmarksDetails[bookmarkId2].name = newName;
      localStorage.setItem(
        "user_bookmarks",
        JSON.stringify(storedBookmarksDetails)
      );
      return {
        ...state,
        bookmarks: storedBookmarksDetails,
        isBookmarkFilled: false,
      };

    case ON_DELETE_BOOKMARK:
      const bookmarkId3 = action.value;
      const updatedBookmarks = state.bookmarks.filter(
        (_, index) => index !== bookmarkId3
      );
      localStorage.setItem("user_bookmarks", JSON.stringify(updatedBookmarks));
      if (state.selectedBookmarkIdxDefault === bookmarkId3) {
        localStorage.setItem("mark_as_default", "-1");
      }
      if (state.selectedBookmarkIdx === bookmarkId3) {
        localStorage.setItem("selected_bookmark", "-1");
      }
      return {
        ...state,
        bookmarks: updatedBookmarks,
        ...(state.selectedBookmarkIdxDefault === bookmarkId3
          ? { selectedBookmarkIdxDefault: -1 }
          : {}),
        ...(state.selectedBookmarkIdx === bookmarkId3
          ? { selectedBookmarkIdx: -1, isBookmarkFilled: false }
          : {}),
      };
    case MARK_AS_DEFAULT:
      const bookmarkId4 = action.value;
      localStorage.setItem("mark_as_default", bookmarkId4);
      return {
        ...state,
        selectedBookmarkIdxDefault: bookmarkId4,
        isBookmarkFilled: bookmarkId4 >= 0,
      };
    case ADD_BOOKMARK_ACTION:
      const bookmarkNumber = state.bookmarks.length;
      const bookmarks = [
        ...state.bookmarks,
        {
          id: bookmarkNumber,
          name: `Bookmark ${bookmarkNumber + 1}`,
          filters: JSON.stringify(state.selectedFilterOptions),
        },
      ];
      localStorage.setItem("user_bookmarks", JSON.stringify(bookmarks));
      localStorage.setItem("selected_bookmark", JSON.stringify(bookmarkNumber));
      return {
        ...state,
        bookmarks,
        selectedBookmarkIdx: bookmarkNumber,
        isBookmarkFilled: true,
      };
    case ON_SELECT_BOOKMARK:
      const filters5 = JSON.parse(
        state.bookmarks[action.value].filters
      ) as IFilterOptions;
      localStorage.setItem("selected_bookmark", action.value);
      return {
        ...state,
        selectedFilters: transformFilterOptions(filters5),
        selectedFilterOptions: filters5,
        selectedBookmarkIdx: action.value,
        isBookmarkFilled: true,
      };
    case GET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptionsLoading: {
          ...state.filterOptionsLoading,
          [action.value.type]: true,
        },
        filterOptions: {
          ...state.filterOptions,
          [action.value.type]: [],
        },
      };
    case GET_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        filterOptionsLoading: {
          ...state.filterOptionsLoading,
          [action.value.type]: false,
        },
        filterOptions: {
          ...state.filterOptions,
          [action.value.type]: transformFilters(
            action.value.type,
            action.value.options
          ),
        },
      };
    case GET_FILTER_OPTIONS_ERROR:
      return {
        ...state,
        filterOptionsLoading: {
          ...state.filterOptionsLoading,
          [action.value.type]: false,
        },
        filterOptions: {
          ...state.filterOptions,
          [action.value.type]: [],
        },
      };
    case GET_UNITS_DATA:
      return {
        ...state,
        isUnitsDataLoading: 2,
        sellingUnitsData: {},
        shippingUnitsData: {},
        vendorUnitsData: {},
        totalWarehouseData: {},
        totalStoreData: {},
      };
    case GET_UNITS_DATA_SUCCESS:
      return {
        ...state,
        isUnitsDataLoading: state.isUnitsDataLoading - 1,
        sellingUnitsData: {
          ...state.sellingUnitsData,
          ...action.value.sellingUnits,
        },
        shippingUnitsData: {
          ...state.shippingUnitsData,
          ...action.value.shippingUnits,
        },
        vendorUnitsData: {
          ...state.vendorUnitsData,
          ...action.value.vendorUnits,
        },
        totalWarehouseData: {
          ...state.totalWarehouseData,
          ...action.value.totalWarehouseData,
        },
        totalStoreData: {
          ...state.totalStoreData,
          ...action.value.totalStoreData,
        },
      };
    case GET_UNITS_DATA_ERROR:
      return {
        ...state,
        isUnitsDataLoading: state.isUnitsDataLoading - 1,
      };
    case FLOATING_RESET_ACTION:
      return {
        ...state,
        floatingResetKey: state.floatingResetKey + 1,
      };
    case FILTERS_RESET_ACTION:
      return {
        ...state,
        filtersResetKey: state.filtersResetKey + 1,
        selectedFilters: defaultFilters,
        selectedFilterOptions: defaultFilterOptions,
        filterOptions: defaultFilterOptions,
        selectedBookmarkIdx: -1,
        isBookmarkFilled: false,
      };
    case REFRESH_DATA_ACTION:
      return {
        ...state,
        refreshDataKey: state.refreshDataKey + 1,
      };
    case CHANGE_EXPAND_FLOATING_MENU_ACTION:
      return {
        ...state,
        floatingMenuChange: action.value,
      };
    case FILTER_ACCORDIAN_OPEN:
      return {
        ...state,
        filterAccordianOpen: action.value,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
