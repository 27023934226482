import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import Button from "@albertsons/uds/molecule/Button";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import ReportHeader from "../../components/Report/ReportHeader";
import { CATEGORY } from "../../types/category.types";

interface HomeProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  shouldHaveCategory?: boolean;
}

const Home: FC<HomeProps> = (props) => {
  const { forceScreen, shouldHaveCategory = false } = props;

  const [isInitialized, setIsInitialized] = useState(false);

  const navigate = useNavigate();

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  useEffect(() => {
    if (!isInitialized) {
      const category = localStorage.getItem("category");
      if (category) {
        navigate(category);
      } else if (shouldHaveCategory) {
        navigate(CATEGORY.WAREHOUSE_FAR);
      } else {
        setIsInitialized(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isInitialized) {
    return null;
  }

  /* istanbul ignore next */
  return (
    <>
      <ReportHeader forceScreen={forceScreen} showMviewLabel />
      <div
        className="flex flex-col items-center gap-8 pt-14 bg-[#d1e5f3]"
        style={{ height: "calc(100vh - 64px)" }}
      >
        <h5 className="text-xl font-semibold">Choose Category</h5>
        <div
          className={clsx(
            "flex gap-8 px-16 w-full",
            screen.small && "flex-col"
          )}
        >
          <Button
            variant="secondary"
            className="!rounded-full text-black border-none shadow-lg"
            onClick={() => {
              localStorage.setItem("category", CATEGORY.WAREHOUSE_FAR);
              navigate(CATEGORY.WAREHOUSE_FAR);
            }}
          >
            Warehouse FaR
          </Button>
          <Button
            variant="secondary"
            className="!rounded-full text-black border-none shadow-lg"
            onClick={() => {
              localStorage.setItem("category", CATEGORY.WAREHOUSE_NON_FAR);
              navigate(CATEGORY.WAREHOUSE_NON_FAR);
            }}
          >
            Warehouse non-FaR
          </Button>
          <Button
            variant="secondary"
            className="!rounded-full text-black border-none shadow-lg"
            onClick={() => {
              localStorage.setItem("category", CATEGORY.DSD_NON_FRESH);
              navigate(CATEGORY.DSD_NON_FRESH);
            }}
          >
            DSD non-Fresh
          </Button>
        </div>
        <p className="text-base font-medium text-center px-16">
          (You can change this later at the top of your summary page)
        </p>
      </div>
    </>
  );
};

export default Home;
