import { call, put } from "redux-saga/effects";
import {
  getWarehouseKPIData,
  getWarehouseKPIDsdValues,
  getStoreKPIData,
  getStoreKPIDsdData,
  getFilters,
  getCICAndUPCDetails,
} from "../end-points/dashboard";

import {
  fetchUnitsData,
  fetchUnitsDataSuccess,
  fetchUnitsDataError,
  fetchFilterOptions,
  fetchFilterOptionsSuccess,
  fetchFilterOptionsError,
  fetchCICAndUPCDetails,
  fetchCICAndUPCDetailsSuccess,
} from "../modules/dashboard";
import { formatFilterOptions } from "../../utils/formatFilterOptions";
import { ISuccessResponse } from "../../types/response.types";
import { IFilterOption } from "../../types/dropdown.types";
import {
  IFilter,
  IFilterOptions,
  UpcDetailData,
} from "../../types/filters.types";
import { IUnits, IUnitsTotalData } from "../../types/data.types";
import { CATEGORY } from "../../types/category.types";

interface WarehouseKPIData {
  getWarehouseKPIValues: {
    onOrderSellingUnits?: IUnits;
    inTransitToDcSellingUnits?: IUnits;
    dcInventorySellingUnits?: IUnits;
    onOrderShippingUnits?: IUnits;
    inTransitToDcShippingUnits?: IUnits;
    dcInventoryShippingUnits?: IUnits;
    onOrderVendorUnits?: IUnits;
    inTransitToDcVendorUnits?: IUnits;
    dcInventoryVendorUnits?: IUnits;
  } & IUnitsTotalData;
}

interface WarehouseKPIDsdData {
  getWarehouseKPIDsdValues: {
    storeOnOrderSellingUnits?: IUnits;
    storeOnOrderShippingUnits?: IUnits;
    storeOnOrderVendorUnits?: IUnits;
  } & IUnitsTotalData;
}

interface StoreKPIData {
  getStoreKPIValues: {
    inTransitToStoreSellingUnits?: IUnits;
    storeInventorySellingUnits?: IUnits;
    inTransitToStoreShippingUnits?: IUnits;
    storeInventoryShippingUnits?: IUnits;
    inTransitToStoreVendorUnits?: IUnits;
    storeInventoryVendorUnits?: IUnits;
  } & IUnitsTotalData;
}

interface StoreKPIDsdData {
  getStoreKPIDsdValues: {
    inTransitToStoreSellingUnits?: IUnits;
    storeInventorySellingUnits?: IUnits;
    inTransitToStoreShippingUnits?: IUnits;
    storeInventoryShippingUnits?: IUnits;
    inTransitToStoreVendorUnits?: IUnits;
    storeInventoryVendorUnits?: IUnits;
  } & IUnitsTotalData;
}

export const transformFilterOptions = (
  filterOptions: IFilterOptions
): IFilter => ({
  warehouseDivision: formatFilterOptions(
    filterOptions.warehouseDivision,
    "label"
  ),
  warehouse: formatFilterOptions(filterOptions.warehouse, "value"),
  distribution: formatFilterOptions(filterOptions.distribution, "label"),
  dsdVendor: formatFilterOptions(filterOptions.dsdVendor, "value"),
  retailDivision: formatFilterOptions(filterOptions.retailDivision, "label"),
  retailStore: formatFilterOptions(filterOptions.retailStore, "label"),
  smic: formatFilterOptions(filterOptions.smic, "label"),
  upc: formatFilterOptions(filterOptions.upc, "value"),
  cic: filterOptions.cic.length > 0 ? `${filterOptions.cic[0].value}` : "",
  department: formatFilterOptions(filterOptions.department, "value", true),
  retailSection: formatFilterOptions(
    filterOptions.retailSection,
    "value",
    true
  ),
});

export function* getWarehouseKPIDataSaga(
  action: ReturnType<typeof fetchUnitsData>
): any {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    if (
      category === CATEGORY.WAREHOUSE_FAR ||
      category === CATEGORY.WAREHOUSE_NON_FAR
    ) {
      const dcFacilityInventoryResponse: ISuccessResponse<WarehouseKPIData> =
        yield call(
          getWarehouseKPIData,
          transformFilterOptions(filters),
          category === CATEGORY.WAREHOUSE_FAR
        );
      const data = dcFacilityInventoryResponse.data.getWarehouseKPIValues;
      const sellingUnits = {
        dcInventory: data.dcInventorySellingUnits,
        inTransitToDC: data.inTransitToDcSellingUnits,
        onOrder: data.onOrderSellingUnits,
      };
      const shippingUnits = {
        dcInventory: data.dcInventoryShippingUnits,
        inTransitToDC: data.inTransitToDcShippingUnits,
        onOrder: data.onOrderShippingUnits,
      };
      const vendorUnits = {
        dcInventory: data.dcInventoryVendorUnits,
        inTransitToDC: data.inTransitToDcVendorUnits,
        onOrder: data.onOrderVendorUnits,
      };
      const total = {
        fourWeekFrwdDemandSellingUnits: data.fourWeekFrwdDemandSellingUnits,
        eightWeekHstrSelnSellingUnits: data.eightWeekHstrSelnSellingUnits,
        fourWeekFrwdDemandShippingUnits: data.fourWeekFrwdDemandShippingUnits,
        eightWeekHstrSelnShippingUnits: data.eightWeekHstrSelnShippingUnits,
        fourWeekFrwdDemandVendorUnits: data.fourWeekFrwdDemandVendorUnits,
        eightWeekHstrSelnVendorUnits: data.eightWeekHstrSelnVendorUnits,
      };
      yield put(
        fetchUnitsDataSuccess({
          sellingUnits,
          shippingUnits,
          vendorUnits,
          totalWarehouseData: total,
          totalStoreData: {},
        })
      );
    } else {
      const dcFacilityInventoryResponse: ISuccessResponse<WarehouseKPIDsdData> =
        yield call(getWarehouseKPIDsdValues, transformFilterOptions(filters));
      const data = dcFacilityInventoryResponse.data.getWarehouseKPIDsdValues;
      const sellingUnits = {
        onOrder: data.storeOnOrderSellingUnits,
      };
      const shippingUnits = {
        onOrder: data.storeOnOrderShippingUnits,
      };
      const vendorUnits = {
        onOrder: data.storeOnOrderVendorUnits,
      };
      const total = {
        fourWeekFrwdDemandSellingUnits: data.fourWeekFrwdDemandSellingUnits,
        eightWeekHstrSelnSellingUnits: data.eightWeekHstrSelnSellingUnits,
        fourWeekFrwdDemandShippingUnits: data.fourWeekFrwdDemandShippingUnits,
        eightWeekHstrSelnShippingUnits: data.eightWeekHstrSelnShippingUnits,
        fourWeekFrwdDemandVendorUnits: data.fourWeekFrwdDemandVendorUnits,
        eightWeekHstrSelnVendorUnits: data.eightWeekHstrSelnVendorUnits,
      };
      yield put(
        fetchUnitsDataSuccess({
          sellingUnits,
          shippingUnits,
          vendorUnits,
          totalWarehouseData: total,
          totalStoreData: {},
        })
      );
    }
  } catch (error: any) {
    console.log("getWarehouseKPIDataSaga Error:", error);
    yield put(fetchUnitsDataError());
  }
}

export function* getStoreKPIDataSaga(
  action: ReturnType<typeof fetchUnitsData>
): any {
  const { filters } = action.value;
  const category = window.location.pathname;
  /* istanbul ignore next */
  try {
    if (
      category === CATEGORY.WAREHOUSE_FAR ||
      category === CATEGORY.WAREHOUSE_NON_FAR
    ) {
      const dcFacilityInventoryResponse: ISuccessResponse<StoreKPIData> =
        yield call(
          getStoreKPIData,
          transformFilterOptions(filters),
          category === CATEGORY.WAREHOUSE_FAR
        );
      const data = dcFacilityInventoryResponse.data.getStoreKPIValues;
      const sellingUnits = {
        inTransitToStore: data.inTransitToStoreSellingUnits,
        storeInventory: data.storeInventorySellingUnits,
      };
      const shippingUnits = {
        inTransitToStore: data.inTransitToStoreShippingUnits,
        storeInventory: data.storeInventoryShippingUnits,
      };
      const vendorUnits = {
        inTransitToStore: data.inTransitToStoreVendorUnits,
        storeInventory: data.storeInventoryVendorUnits,
      };
      const total = {
        fourWeekFrwdDemandSellingUnits: data.fourWeekFrwdDemandSellingUnits,
        eightWeekHstrSelnSellingUnits: data.eightWeekHstrSelnSellingUnits,
        fourWeekFrwdDemandShippingUnits: data.fourWeekFrwdDemandShippingUnits,
        eightWeekHstrSelnShippingUnits: data.eightWeekHstrSelnShippingUnits,
        fourWeekFrwdDemandVendorUnits: data.fourWeekFrwdDemandVendorUnits,
        eightWeekHstrSelnVendorUnits: data.eightWeekHstrSelnVendorUnits,
      };
      yield put(
        fetchUnitsDataSuccess({
          sellingUnits,
          shippingUnits,
          vendorUnits,
          totalWarehouseData: {},
          totalStoreData: total,
        })
      );
    } else {
      const dcFacilityInventoryResponse: ISuccessResponse<StoreKPIDsdData> =
        yield call(getStoreKPIDsdData, transformFilterOptions(filters));
      const data = dcFacilityInventoryResponse.data.getStoreKPIDsdValues;
      const sellingUnits = {
        inTransitToStore: data.inTransitToStoreSellingUnits,
        storeInventory: data.storeInventorySellingUnits,
      };
      const shippingUnits = {
        inTransitToStore: data.inTransitToStoreShippingUnits,
        storeInventory: data.storeInventoryShippingUnits,
      };
      const vendorUnits = {
        inTransitToStore: data.inTransitToStoreVendorUnits,
        storeInventory: data.storeInventoryVendorUnits,
      };
      const total = {
        fourWeekFrwdDemandSellingUnits: data.fourWeekFrwdDemandSellingUnits,
        eightWeekHstrSelnSellingUnits: data.eightWeekHstrSelnSellingUnits,
        fourWeekFrwdDemandShippingUnits: data.fourWeekFrwdDemandShippingUnits,
        eightWeekHstrSelnShippingUnits: data.eightWeekHstrSelnShippingUnits,
        fourWeekFrwdDemandVendorUnits: data.fourWeekFrwdDemandVendorUnits,
        eightWeekHstrSelnVendorUnits: data.eightWeekHstrSelnVendorUnits,
      };
      yield put(
        fetchUnitsDataSuccess({
          sellingUnits,
          shippingUnits,
          vendorUnits,
          totalWarehouseData: {},
          totalStoreData: total,
        })
      );
    }
  } catch (error: any) {
    console.log("getStoreKPIDataSaga Error:", error);
    yield put(fetchUnitsDataError());
  }
}

export function* getFilterOptionsSaga(
  action: ReturnType<typeof fetchFilterOptions>
): any {
  const { type, query, filters } = action.value;
  const category = window.location.pathname as CATEGORY;
  try {
    const response: ISuccessResponse<IFilterOption[]> = yield call(
      getFilters,
      type,
      query,
      category,
      filters
    );

    yield put(fetchFilterOptionsSuccess(type, response.data));
  } catch (error: any) {
    console.log("getFilterOptionsSaga Error:", error);
    yield put(fetchFilterOptionsError(type));
  }
}

/* istanbul ignore next */
export function* getCICAndUPCDetailsSaga(
  action: ReturnType<typeof fetchCICAndUPCDetails>
): any {
  const { data, type } = action.value;
  const category = window.location.pathname as CATEGORY;
  try {
    const response: ISuccessResponse<UpcDetailData> = yield call(
      getCICAndUPCDetails,
      data,
      type,
      category
    );

    yield put(fetchCICAndUPCDetailsSuccess(response.data, type));
  } catch (error: any) {
    console.log("getCICAndUPCDetailsSaga Error:", error);
  }
}
