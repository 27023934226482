import { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "@albertsons/uds/molecule/Select";
import { AppDispatch } from "../../store";
import { filtersResetAllAction } from "../../store/modules/dashboard";
import { IDropdownOption } from "../../types/dropdown.types";
import { CATEGORY } from "../../types/category.types";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import "./styles.css";

export const CATEGORY_OPTIONS: IDropdownOption[] = [
  { label: "Warehouse FaR", value: CATEGORY.WAREHOUSE_FAR },
  { label: "Warehouse non-FaR", value: CATEGORY.WAREHOUSE_NON_FAR },
  { label: "DSD non-Fresh", value: CATEGORY.DSD_NON_FRESH },
];

interface CategoryToggleProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  filtersResetAll: () => void;
}

const CategoryToggle: FC<CategoryToggleProps> = (props) => {
  const { forceScreen, filtersResetAll } = props;

  const [selectedCategory, setSelectedCategory] = useState<IDropdownOption>();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  useEffect(() => {
    if (pathname === CATEGORY.WAREHOUSE_FAR) {
      setSelectedCategory(CATEGORY_OPTIONS[0]);
    } else if (pathname === CATEGORY.WAREHOUSE_NON_FAR) {
      setSelectedCategory(CATEGORY_OPTIONS[1]);
    } else if (pathname === CATEGORY.DSD_NON_FRESH) {
      setSelectedCategory(CATEGORY_OPTIONS[2]);
    }
  }, [pathname]);

  /* istanbul ignore next */
  const onCategoryChangeHandler = (item: IDropdownOption) => {
    localStorage.setItem("category", item.value);
    filtersResetAll();
    navigate(item.value);
  };

  return (
    <div className="category-toggle">
      <Select<IDropdownOption>
        className={clsx(
          "w-full mt-[2px] border border-[#e5e7eb] !bg-[#e6e6e6]",
          screen.small ? "text-sm" : "text-xs !pr-1 !w-[166px]"
        )}
        placeholder="Choose a category"
        selectionPlaceholder={
          selectedCategory
            ? `Category: ${selectedCategory.label}`
            : "Choose a category"
        }
        items={CATEGORY_OPTIONS}
        id="category"
        itemText="label"
        itemKey="value"
        size={screen.small ? "md" : "sm"}
        noTags
        value={selectedCategory}
        onChange={onCategoryChangeHandler}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  filtersResetAll: () => dispatch(filtersResetAllAction()),
});

export default connect(null, mapDispatchToProps)(CategoryToggle);
