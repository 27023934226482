import { call, put } from "redux-saga/effects";

import {
  fetchOrderHistoryDsdData,
  fetchOrderHistoryDsdDataError,
  fetchOrderHistoryDsdDataSuccess,
  fetchOrderHistoryDsdTotalQuantity,
  fetchOrderHistoryDsdTotalQuantitySuccess,
} from "../modules/dsdOrderHistory";
import {
  getOrderHistoryDsdData,
  getOrderHistoryDsdTotalQuantity,
} from "../end-points/dsdOrderHistory";
import { transformFilterOptions } from "../../utils/formatFilterOptions";
import { ISuccessResponse } from "../../types/response.types";
import { IOrderDsd } from "../../types/order.types";

interface IOrderHistoryDsdData {
  getOrderHistoryDsd: {
    storeOrdersDsd: IOrderDsd[];
  };
}

interface IOrderHistoryDsdTotalQuantity {
  getOrderHistoryTotalDsd: {
    storeOrderTotalQtyDsd: number;
  };
}

export function* getOrderHistoryDsdDataSaga(
  action: ReturnType<typeof fetchOrderHistoryDsdData>
) {
  const { filters } = action.value;
  try {
    const response: ISuccessResponse<IOrderHistoryDsdData> = yield call(
      getOrderHistoryDsdData,
      transformFilterOptions(filters)
    );
    const data = response.data.getOrderHistoryDsd?.storeOrdersDsd || [];
    yield put(fetchOrderHistoryDsdDataSuccess(data));
  } catch (error) {
    console.log("getOrderHistoryDsdDataSaga Error:", error);
    yield put(fetchOrderHistoryDsdDataError());
  }
}

export function* getOrderHistoryDsdTotalQuantitySaga(
  action: ReturnType<typeof fetchOrderHistoryDsdTotalQuantity>
) {
  const { filters } = action.value;
  try {
    const response: ISuccessResponse<IOrderHistoryDsdTotalQuantity> =
      yield call(
        getOrderHistoryDsdTotalQuantity,
        transformFilterOptions(filters)
      );
    const storeOrderTotalQtyDsd =
      response.data.getOrderHistoryTotalDsd?.storeOrderTotalQtyDsd || 0;
    yield put(fetchOrderHistoryDsdTotalQuantitySuccess(storeOrderTotalQtyDsd));
  } catch (error) {
    console.log("getOrderHistoryDsdTotalQuantitySaga Error:", error);
  }
}
